
import MultiChoice from './MultiChoice.vue';
import ProceedButton from './ProceedButton.vue';
import ShareFile from './ShareFile.vue';
import fileTypes from '../assets/json/fileTypes.json'

import { defineComponent, PropType } from '@vue/runtime-core';
import { RouteLocationRaw } from 'vue-router';
import { reset, setLastSet } from './module';
import { ChatQuestion, submitFn } from '@/ts/interfaces/Question';
import { dateToDashDelimited, isMultiChoice } from '../helpers';

type isType = 'youtube' | 'image' | 'link';

export default defineComponent({
    props: {
        legend: { type: String }, 
        initiateSubmit: { type: Function as PropType<submitFn>, required: true },
        fallbackRoute: { type: String as PropType<RouteLocationRaw>, required: true },
        onProceed: { type: Function },
        onError: { type: Function }
    },
    async beforeMount(){
        if(!this.$store.hasModule('chat') || !this.questions.length)
            this.$router.replace(this.fallbackRoute);
    },
    unmounted(){
        if(this.$store.hasModule('chat')) reset();
    },
    components: { MultiChoice, ProceedButton, ShareFile },
    data: () => {
        const data = { fileTypes };
        if(window.location.pathname.includes('intake'))
            Object.assign(data, { edit: null, lists: { diseases: [], medication: [] } });
        return data;
    },
    computed: {
        questions(){
            return this.$store.state.chat?.questions ?? []
        }
    },
    methods: {
        isMultiChoice,
        dateToDashDelimited,
        isType(type: isType, { link }: ChatQuestion){
            if(!link)
                return false;

            const isYoutube = /\|yt\|/.test(link);
            const isImage = /\|img\|/i.test(link);
            switch(true){
                case type === 'youtube' && isYoutube:
                case type === 'image' && isImage:
                case type === 'link' && !isImage && !isYoutube:
                    return true;
                default:
                    return false;
            }
        },
        refresh(question: ChatQuestion, key: number){
            if(!question.mark)
                return;
                
            setLastSet(key);
            question.mark = false;
            for(let i = key + 1; i < this.questions.length; i++)
                if(this.questions[i].show)
                    Object.assign(this.questions[i], { show: false, mark: false });
        },
    }
});
