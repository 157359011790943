<template>
    <div v-if='q.allow_alt === "file"' class='alt'>
        אם לא מתאים לך לכתוב, אפשר גם פשוט להעלות תמונה:
    </div>
    <span v-if='q.type == "file" || q.allow_alt == "file"' :id='`container${qKey}`'>
        <input type='hidden' :name='`${q.type == "file" ? "questions" : "alt"}[${q.code}]`' value='FILE'/>
        <label v-show='q.iter == index+1' v-for='(iter, index) in q.iter' class='files' :key='index'>
            הוסיפו {{ fileTypes[q.f || 0]['display'] }}
            <img :src='`/images/add-${fileTypes[q.f || 0].name}.png`' width='30px'/>
            <input
              @change='previewFiles(q, qKey)'
              :name='`questions[${q.code}][]`'
              type='file'
              multiple='multiple'
              accept='image/*,.pdf'
            />
        </label>
        <div class='preview' :id='`preview${qKey}`'></div>
    </span>
</template>

<script>
import fileTypes from '../assets/json/fileTypes.json'
export default {
    props: ['q', 'qKey'],
    data: () => ({ fileTypes }),
    methods: {
        previewFiles: function(q,key) {
            if((q.f && q.f===0) || !q.f){
                this.questions[key].iter++;
                let preview = document.querySelector('#preview'+key),
                  fInputs = document.querySelector('#container'+key).querySelectorAll('input[type=file]');
                preview.style.marginTop='1rem';
                preview.style.height='6rem';
                while(preview.children[0]) preview.removeChild(preview.children[0]);
                if(fInputs.length)
                    fInputs.forEach((files) => [].forEach.call(files.files, (file) => {
                        if (!/\.(jpe?g|png|gif)$/i.test(file.name))
                            return;

                        let reader = new FileReader();
                        reader.addEventListener('load', function () {
                            let image = new Image();
                            image.title = file.name;
                            image.src = this.result;
                            preview.appendChild(image);
                        }, false);
                        reader.readAsDataURL(file);
                    }));
            }
        },
    }
}
</script>