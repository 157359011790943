
/* eslint-disable vue/no-mutating-props */ //NOT GOOD!

import { ChatQuestion } from '@/ts/interfaces/Question';
import { defineComponent, PropType } from '@vue/runtime-core';

export default defineComponent({
    props: {
        refresh: { type: Function, required: true },
        q: { type: Object as PropType<ChatQuestion>, required: true },
        qKey: { type: Number, required: true }
    },
    methods: {
        multiRefresh({ target }: { target: HTMLInputElement }, value?: string){
            if(target.type === 'checkbox' || value === 'other')
                this.setMulti();
            else if(target.type === 'radio')
                this.q.val = parseInt(target.value);
            this.refresh(this.q, this.qKey, value);
        },
        setMulti(){
            const { type, altVal } = this.q;
            const selected = this.q.selected as { [key: number]: boolean };
            
            if(type.startsWith('radio'))
                return Object.assign(this.q, { selected: 'other', val: altVal });

            let values = [];
            for(const s in selected) if(selected[s]){
                if(s === 'other'){
                    if(!altVal) continue;
                    values.push(`"${altVal}"`);
                }else if(s === 'na'){
                    values.push(`"na"`);
                }else{
                    values.push(s)
                }
            }
            this.q.val = `[${values.join(',')}]`;
        },
    },
});
