<template>
    <section>
        <slot name='top'></slot>
        <!-- THIS IS FOR ANONYMOUS INTAKE
            @if(!is_null($token))
                <input name='token' type='hidden' value='{{ $token }}'/>
                <input name='code' type='hidden' value='{{ $request->code }}'/>
            @endif
        -->
        <div class='legend' v-if='legend'>{{ legend }}</div>
        <div class='dailyShow' v-for='(q, key) of questions' :key='q.code'>
            <div
                v-if='q.type==="legend" && q.show !== 0'
                class='legend'
                :id='`question${key}`'
            >
                {{ q.text }}
            </div>
            <div v-else-if='q.show' class='chat transparentInputs'>
                <div class='separator' v-if='!q.tree_path && questions[key-1] && questions[key-1].type !== "legend"' />
                <div class='question' :id='`question${key}`'>
                    {{ $parse(q.text) }}
                    <div v-if='q.type.startsWith("checkbox")' class='checkboxMsg'>
                        ניתן לסמן יותר מתשובה אחת
                    </div>
                    <div v-if='q.type === "date"' class='checkboxMsg'>
                        ניתן למלא תאריך משוער
                    </div>
                    <div v-if='isType("link", q)' class='link'>
                        <a :href='q.link'>{{ q.link }}</a>
                    </div>
                </div>
                <img v-if='isType("image", q)' class='response' :src='`/storage/chat/${q.link.replace("|img|","")}`'/>
                <div v-else-if='isType("youtube", q)' class='youtube'>
                    <iframe
                        :src='`https://www.youtube.com/embed/${q.link.replace("|yt|","")}`'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                    ></iframe>
                </div>
                <div v-if='q.type !== "response"' class='answer'>
                    <textarea v-if='q.type === "freetext"' v-model='q.val' :aria-labelledby='`question${key}`' />
                    <input
                        type='number'
                        v-else-if='q.type === "num"'
                        v-model='q.val'
                        @input='refresh(q, key)'
                        class='input'
                    />
                    <multi-choice v-else-if='isMultiChoice(q)' :refresh='refresh' :q='q' :qKey='key'/>
                    <div v-else-if='q.type === "objective"' class='objective'>
                        <label v-for='(param,index) in q.options' :key='index'>
                            {{ param }}
                            <input type='number' v-model='q.val[index]' />
                        </label>
                    </div>
                    <date-field
                        v-else-if='q.type === "date"'
                        :style='{ justifyContent: "center" }'
                        :minDate='new Date(2020, 0, 1)'
                        :maxDate='new Date()'
                        :modelValue='new Date(q.val || Date.now())'
                        @update:modelValue='v => { q.val = dateToDashDelimited(v); q.altVal = null }'
                        :filterYearsOnly='true'
                    />
                    <label v-if='q.type === "date" && q.allow_alt' class='doNotRemember'>
                        <input type='checkbox' v-model='q.altVal' />
                        אני לא {{ $parse('זוכר', 'זוכרת') }}
                    </label>
                    <share-file :q='q' :qKey='key' />
                    <div v-if='q.allow_alt === "freetext"' class='alt'>
                        <span :id='`alt${key}`'>אפשר גם פשוט לכתוב כאן:</span>
                        <textarea :name='`alt[${q.code}]`' v-model='q.val' :aria-labelledby='`alt${key}`' />
                    </div>
                </div>
            </div>
            <proceed-button :qKey='key' v-bind='{ initiateSubmit, onProceed, onError }' />
        </div>
    </section>
</template>

<script lang='ts'>
import MultiChoice from './MultiChoice.vue';
import ProceedButton from './ProceedButton.vue';
import ShareFile from './ShareFile.vue';
import fileTypes from '../assets/json/fileTypes.json'

import { defineComponent, PropType } from '@vue/runtime-core';
import { RouteLocationRaw } from 'vue-router';
import { reset, setLastSet } from './module';
import { ChatQuestion, submitFn } from '@/ts/interfaces/Question';
import { dateToDashDelimited, isMultiChoice } from '../helpers';

type isType = 'youtube' | 'image' | 'link';

export default defineComponent({
    props: {
        legend: { type: String }, 
        initiateSubmit: { type: Function as PropType<submitFn>, required: true },
        fallbackRoute: { type: String as PropType<RouteLocationRaw>, required: true },
        onProceed: { type: Function },
        onError: { type: Function }
    },
    async beforeMount(){
        if(!this.$store.hasModule('chat') || !this.questions.length)
            this.$router.replace(this.fallbackRoute);
    },
    unmounted(){
        if(this.$store.hasModule('chat')) reset();
    },
    components: { MultiChoice, ProceedButton, ShareFile },
    data: () => {
        const data = { fileTypes };
        if(window.location.pathname.includes('intake'))
            Object.assign(data, { edit: null, lists: { diseases: [], medication: [] } });
        return data;
    },
    computed: {
        questions(){
            return this.$store.state.chat?.questions ?? []
        }
    },
    methods: {
        isMultiChoice,
        dateToDashDelimited,
        isType(type: isType, { link }: ChatQuestion){
            if(!link)
                return false;

            const isYoutube = /\|yt\|/.test(link);
            const isImage = /\|img\|/i.test(link);
            switch(true){
                case type === 'youtube' && isYoutube:
                case type === 'image' && isImage:
                case type === 'link' && !isImage && !isYoutube:
                    return true;
                default:
                    return false;
            }
        },
        refresh(question: ChatQuestion, key: number){
            if(!question.mark)
                return;
                
            setLastSet(key);
            question.mark = false;
            for(let i = key + 1; i < this.questions.length; i++)
                if(this.questions[i].show)
                    Object.assign(this.questions[i], { show: false, mark: false });
        },
    }
});
</script>

<style scoped lang="scss">
section {
    margin: 3rem auto 5rem;

    & .dailyShow:first-child .legend {
        margin-top: 0;
    }
}
</style>

<style lang="scss">
.dailyShow {
    width: 24rem;
    margin: 0 auto;
}

.dailyShow, .chat {
    & > * { margin: 0 auto; }
}
.chat {
    display: contents;
    z-index: 1;

    & label[multi-choice] {
        text-indent: -1.5rem;
    }

    & .separator {
        height: 1px;
        width: 50%;
        background-color: $swatchA;
        margin: 3rem;
    }
    & .question, .answer {
        width: 75%;
        position: relative;
        padding: 1rem 1rem;
        margin-top: 1rem;
        border-radius: $corner;
        @include shadow;
    }
    & .question {
        right: -3rem;
        background-color: $swatchB;

        & .link { text-align: center }
        & .checkboxMsg { font-size: 0.75rem }
    }
    & .response {
        max-height: 15rem;
    }
    & .youtube {
        margin-top: 1.5rem;
        width: 15rem;

        & iframe {
            border-radius: $corner;
            @include shadow;
            transform: translateX(5rem);
            height: 15rem;
            width: 26.6rem;
        }
    }
    & .answer {
        left: -3rem;
        background-color: $swatchC;
    }
    & .radio {
        & > label {
            margin: 0.2rem 0;
            & > span { margin-right: 0.5rem }
        }
        & .other input {
            position: relative;
            right: 0.2rem;
            width: 95%;
        }
        & .na {
            margin-top: 0.5rem;
        }
    }
    & .objective {
        & label, & input {
            margin: 0.25rem 0 0;
        }
        & label:last-child {
            margin-bottom: 0.25rem;
        }
    }
    & .radio, & .objective {
        width: 90%;
        margin: auto;
    }
    & .preview {
        width: 100%;
        @include flexbox(around,false,wrap);

        & img {
            max-width: 6rem;
        }
    }
    & .files {
        width: 100%;
        @include flexbox(center-all);

        &:hover {
            cursor: pointer;
        }
        & img {
            margin-right: 1rem;
        }
        & input {
            display: none;
        }
    }
    & .alt {
        font-style: italic;
        text-align: center;
        margin: 0 auto;
        padding-top: 1.5rem;
    }
}

.legend {
    padding: 1rem;
    margin: 3rem auto 2rem;
    width: 100%;
    text-align: center;
    background-color: $swatchA;
    border-radius: $corner;
    @include shadow;
	position: relative;
}

label.doNotRemember {
    margin: 1.2rem auto -.3rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    transform: translateX(.6rem);
    cursor: pointer;
}

@media only screen and (max-width: 768px){
    .daily {
        & .button {
            margin-top: 1rem;
        }
        & h1 {
            padding-bottom: 1.5rem;
        }
    }
    .dailyShow{
        & .question { right: -10vw }
        width: 80vw;
        & .answer {
            left: -10vw;
            & .textcontainer {width: 52vw;}
        }
    }
    .chat .radio .other input {
        width: 100%;
    }
}
</style>