<!-- eslint-disable vue/no-mutating-props -->

<template>
  <div class='radio'>
      <label multi-choice v-for='(option, value) in q.options' :key='value'>
          <input v-if='q.type.startsWith("radio")'
              type='radio'
              v-model='q.selected'
              :name='`radio[${qKey}]`'
              :value='value'
              @click='multiRefresh($event, value)'
          />
          <input v-else
              type='checkbox'
              v-model='q.selected[value]'
              @change='multiRefresh($event)'
          />
          <span v-if='value !== "other"'>
              {{ $parse(option) }}
          </span>
          <span v-else class='other'>
              אחר:
              <input
                  v-model='q.altVal'
                  @input='setMulti(q)'
                  @click='q.selected.other = true; setMulti(q)'
              />
          </span>
      </label>
      <label multi-choice v-if='typeof q.selected?.na !== "undefined"' class='na'>
          <input
              type='checkbox'
              v-model='q.selected.na'
              @change='setMulti(q)'
          />
          <span>אף אחד מהנ"ל</span>
      </label>
      <input v-model='q.val' type='hidden'/>
  </div>
</template>

<script lang='ts'>
/* eslint-disable vue/no-mutating-props */ //NOT GOOD!

import { ChatQuestion } from '@/ts/interfaces/Question';
import { defineComponent, PropType } from '@vue/runtime-core';

export default defineComponent({
    props: {
        refresh: { type: Function, required: true },
        q: { type: Object as PropType<ChatQuestion>, required: true },
        qKey: { type: Number, required: true }
    },
    methods: {
        multiRefresh({ target }: { target: HTMLInputElement }, value?: string){
            if(target.type === 'checkbox' || value === 'other')
                this.setMulti();
            else if(target.type === 'radio')
                this.q.val = parseInt(target.value);
            this.refresh(this.q, this.qKey, value);
        },
        setMulti(){
            const { type, altVal } = this.q;
            const selected = this.q.selected as { [key: number]: boolean };
            
            if(type.startsWith('radio'))
                return Object.assign(this.q, { selected: 'other', val: altVal });

            let values = [];
            for(const s in selected) if(selected[s]){
                if(s === 'other'){
                    if(!altVal) continue;
                    values.push(`"${altVal}"`);
                }else if(s === 'na'){
                    values.push(`"na"`);
                }else{
                    values.push(s)
                }
            }
            this.q.val = `[${values.join(',')}]`;
        },
    },
});
</script>